<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
                    <div class="has-text-centered">
                        <i class="fad fa-plug fa-4x"></i>
                        <h3 class="is-size-3 mt-2">Api Documentation</h3>
                    </div>
                    <hr class="mt-1">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {};
</script>
