var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"has-text-white is-size-4 py-3"},[_vm._v("OwnClipboard")]),_vm._m(0),_c('hr'),_c('h1',{staticClass:"has-text-white is-size-4 pb-4"},[_vm._v("Get started in 4 Easy steps")]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full-mobile is-half-tablet"},[_c('div',{staticClass:"has-background-dark px-3 py-3"},[_vm._m(1),_c('p',{staticClass:"has-text-white is-size-4"},[_vm._v("Sign up")]),_c('p',[_c('router-link',{staticClass:"has-background-success link px-2",attrs:{"to":"/"}},[_vm._v("Signup")]),_vm._v(" for a username and password for your Ownclipboard account, if you have not signed up already. ")],1)])]),_vm._m(2),_c('div',{staticClass:"column is-full-mobile is-half-tablet"},[_c('div',{staticClass:"has-background-dark px-3 py-3"},[_vm._m(3),_c('p',{staticClass:"has-text-white is-size-4"},[_vm._v("Add a Device")]),_c('p',[_vm._v(" To use Ownclipboard on more than one device, Click on "),_c('router-link',{staticClass:"has-background-success link px-2 mx-1",attrs:{"to":"/devices"}},[_vm._v("Account > Devices ")]),_vm._v(" add a new Device, and copy your Api Key. ")],1)])]),_vm._m(4)]),_c('hr'),_c('h1',{staticClass:"has-text-white is-size-4 py-2"},[_vm._v("Download")]),_vm._m(5),_c('h1',{staticClass:"has-text-white is-size-4 py-2"},[_vm._v("Developers")]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"has-background-dark px-3 py-3"},[_c('p',[_vm._v(" Ownclipboard is the fastest way to quickly copy and paste in all your apps. it is more than a clipboard manager, with ownclipboard you can host your own instance of ownclipbaord on your server see guide "),_c('span',[_c('a',{staticClass:"has-background-success link px-2",attrs:{"href":"https://github.com/trapcodeio/ownclipboard","target":"_blank","to":"/docs"}},[_vm._v("here.")])])]),_c('h5',{staticClass:"has-text-white py-2"},[_vm._v(" Ownclipboad has 5 main features that will make it your favorite clipboard manager. ")]),_c('ul',[_c('li',{staticClass:"py-1"},[_vm._v(" - You have the ability to use your custom host provided.")]),_c('li',{staticClass:"py-1"},[_vm._v(" - Copy in 1-Tap to perform quick actions from all the apps.")]),_c('li',{staticClass:"py-1"},[_vm._v(" - An enhanced clipboard to save your copied items.")]),_c('li',{staticClass:"py-1"},[_vm._v(" - A Paste feature which allows you to paste anything from your clipboard in 1-Tap. ")]),_c('li',{staticClass:"py-1"},[_vm._v(" - Synchronize your clipboard history with all your devices.")])])])]),_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image"},[_c('img',{staticClass:"mockup px-3",attrs:{"src":"/img/app1.svg"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"is-size-1"},[_c('i',{staticClass:"fad fa-sign-in"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-full-mobile is-half-tablet"},[_c('div',{staticClass:"has-background-dark px-3 py-3"},[_c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"is-size-1"},[_c('i',{staticClass:"fad fa-paste"})])]),_c('p',{staticClass:"has-text-white is-size-4"},[_vm._v("Paste your clipboard")]),_c('p',[_vm._v(" Paste the content of your clipboard or create new content immediately, you are not limited by what you can paste. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"is-size-1"},[_c('i',{staticClass:"fad fa-mobile"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-full-mobile is-half-tablet"},[_c('div',{staticClass:"has-background-dark px-3 py-3"},[_c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"is-size-1"},[_c('i',{staticClass:"fad fa-link"})])]),_c('p',{staticClass:"has-text-white is-size-4"},[_vm._v("Synchronise your devices")]),_c('p',[_vm._v(" Paste your secure API key on your app and click the request button "),_c('br'),_vm._v(" your clipboard will be automatically Synchronised with your Device.' ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-background-dark px-3 py-3"},[_vm._v(" Ownclipboard is available for Download on the Apple Store and Google Play Store ")]),_c('div',{staticClass:"field is-grouped"},[_c('div',{staticClass:"control my-4"},[_c('button',{staticClass:"button is-success is-outlined is-medium"},[_vm._v("Apple Store "),_c('span',{staticClass:"mx-2"},[_c('i',{staticClass:"fab fa-app-store"})])])]),_c('div',{staticClass:"control my-4"},[_c('a',{staticClass:"button is-success is-outlined is-medium",attrs:{"href":"https://play.google.com/store/apps/details?id=com.ownclipboard.own_clipboard"}},[_vm._v("Google Store "),_c('span',{staticClass:"mx-2"},[_c('i',{staticClass:"fab fa-google-play"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-background-dark px-3 py-3"},[_c('p',[_vm._v(" Are you a developer ? "),_c('br'),_vm._v(" Host your own instance of Ownclipboard and customize to your satisfaction. Ownclipboard is open source and we anxious to see how you tweak and customize it. "),_c('br'),_vm._v("To get stared visit the "),_c('span',[_c('a',{staticClass:"has-background-success link px-2",attrs:{"href":"https://github.com/trapcodeio/ownclipboard","target":"_blank","to":"/docs"}},[_vm._v("Github Repository.")])])])])
}]

export { render, staticRenderFns }